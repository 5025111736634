import { useContext } from "react";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import Text from "components/Text";
import { defaultFieldValues, FORM_MODE } from "../config/enum";
import ModalContainer from "components/Modal";
import { useFormContext } from "react-hook-form";
import moment from "moment";
import Form from "./Form";
import { createTransferStockgudang, updateTransferStockgudang } from "apis/trf_stock_gudang";

const ModalCreate = () => {
  const { openCreate, setOpenCreate, fetchSO, toast, isDetail } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();

  const handleSubmit = forms.handleSubmit(async (values) => {
    try {
      console.log(values.so_details);
      const formattedDetails = values.so_details.map((item) => ({
        ...(values.formType === FORM_MODE.EDIT && {
          id_transfer_stock_detail: item.id_stock_opname_detail,
        }),
        id_product: item.id_product,
        id_unit: 1,
        qty: Number(item.stock_actual),
        // price: item.price,
        notes: item.notes ?? "",
      }));

      const payload = {
        ...(values.formType === FORM_MODE.EDIT && {
          no_ts: values.no_so,
          id_transfer_stock: values.id_stock_opname,
        }),
        status: values.status,
        note: values.note,
        id_user: values.id_user,
        ts_date: moment(values.so_date).format("YYYY-MM-DD"),
        id_gudang_source: values.id_gudang_source,
        id_gudang_destination: values.id_gudang_destination,
        ts_details: formattedDetails,
      };

      if (values.formType === FORM_MODE.CREATE) {
        await createTransferStockgudang(payload);
      } else {
        await updateTransferStockgudang(payload);
      }

      setOpenCreate(false);

      forms.reset(defaultFieldValues);

      fetchSO({ page: 1, limit: 10 });
    } catch (error) {
      toast({
        title: error,
        status: "error",
        isClosable: true,
        position: "top-center",
      });
    }
  });

  const handleDraft = () => {
    forms.setValue("status", 1);
    handleSubmit();
  };

  return (
    <ModalContainer
      isOpen={openCreate}
      modalTitle={`${
        forms.watch("formType") === "create"
          ? "Tambah"
          : forms.watch("formType") === "detail"
          ? "Detail"
          : "Edit"
      } Transfer Stok Gudang`}
      onClose={() => {
        setOpenCreate(false);
        forms.reset(defaultFieldValues);
      }}
      onSubmit={() => {
        forms.setValue("status", 2);
        handleSubmit();
      }}
      isSubmitting={forms.formState.isSubmitting}
      hideButton={isDetail}
      onDraft={handleDraft}
    >
      <Text variant="h3" content="Informasi Dasar" />
      <Form />
    </ModalContainer>
  );
};

export default ModalCreate;
