import DefaultColumn from "components/Table/components/DefaultColumn";
import { MdCreate, MdDelete, MdRemoveRedEye } from "react-icons/md";
import StatusColumn from "../component/StatusColumn";
import * as yup from "yup";
import Checkbox from "components/checkbox";
import { getUser } from "helper/localstorage";
import { currency } from "helper/index";
import InputCell from "../component/InputCell";
import moment from "moment";

const validations = yup.object({
  created_by: yup.string().required("Pembuat PO wajib diisi"),
  // id_region: yup.string().required("Wilayah wajib diisi"),
});

const STATUS_ENUM = {
  DRAFT: 1,
  SUCCESS: 2,
  CANCEL: 5,
};

const FORM_MODE = {
  CREATE: "create",
  EDIT: "edit",
  DETAIL: "detail",
};

const TABLE_COLUMN = (onDetail, onEdit) => [
  {
    accessorKey: "created_at",
    header: "Tanggal",
    cell: (info) => (
      <DefaultColumn>
        {moment(info.getValue()).format("YYYY-MM-DD")}
      </DefaultColumn>
    ),
  },
  {
    accessorKey: "no_ts",
    header: "No. Transfer Stok",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "user.username",
    header: "Dibuat Oleh",
    cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (info) => (
      <StatusColumn status={info.getValue()} data={info.row.original} />
    ),
  },
  {
    accessorKey: "id_product",
    header: "Aksi",
    cell: (info) => {
      return (
        <div className="flex gap-2">
          <button
            className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#EBFAF0] text-[#215759]"
            onClick={() => onDetail(info.row.original)}
          >
            <MdRemoveRedEye />
          </button>
          {/* {info.cell.row.original.status === STATUS_ENUM.DRAFT && (
                        <button className="bg-[#BEDBFE] text-[#3A65FF] rounded-lg w-8 h-8 flex justify-center items-center" onClick={() => onEdit(info.row.original)}>
                            <MdCreate />
                        </button>
                    )} */}
        </div>
      );
    },
  },
];

const TABLE_COLUMN_PRODUCT_SELECTED = (
  handleDelete,
  handleChangeData,
  { isDetail, isEdit }
) => {
  return [
    {
      accessorKey: "product_name",
      header: "Nama produk",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "sku",
      header: "SKU",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "qty",
      header: "Stok Sistem",
      cell: (info) => (
        <InputCell
          info={info}
          disabled
          callback={(value) =>
            handleChangeData("qty", value, info.row.original.id_product)
          }
          isDetail={isDetail}
          isEdit={isEdit}
        />
      ),
    },
    // {
    //     accessorKey: 'unit',
    //     header: 'Satuan',
    //     cell: info => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    // },
    {
      accessorKey: "stock_actual",
      header: "Stok In",
      cell: (info) => (
        <InputCell
          info={info}
          callback={(value) =>
            handleChangeData(
              "stock_actual",
              value,
              info.row.original.id_product
            )
          }
          disabled={isDetail || isEdit}
        />
      ),
    },
    {
      accessorKey: "total_stock",
      header: "Selisih",
      cell: (info) => {
        const stockSystem = parseInt(info.row.original.qty) || 0;
        const stockActual = parseInt(info.row.original.stock_actual) || 0;
        const value = stockSystem - stockActual;
        return <DefaultColumn>{value}</DefaultColumn>;
      },
    },
    {
      accessorKey: "capital_price",
      header: "Hargal Modal",
      cell: (info) => (
        <DefaultColumn>{currency(info.getValue())}</DefaultColumn>
      ),
    },
    {
      accessorKey: "notes",
      header: "Catatan",
      cell: (info) => (
        <InputCell
          type="text"
          info={info}
          disabled={isEdit}
          callback={(value) =>
            handleChangeData("notes", value, info.row.original.id_product)
          }
          isDetail={isDetail}
          isEdit={isEdit}
        />
      ),
    },
    {
      accessorKey: "action",
      header: "Aksi",
      cell: (info) => (
        <button
          disabled={isDetail || isEdit}
          className="flex h-8 w-8 items-center justify-center rounded-lg bg-[#FECACA] text-[#E31B1A] disabled:cursor-not-allowed"
          onClick={() => {
            handleDelete(info.row.original);
          }}
        >
          <MdDelete />
        </button>
      ),
    },
  ];
};

const TABLE_COLUMN_PRODUCT = (onCheckTable) => {
  return [
    {
      accessorKey: "id_product",
      header: "",
      size: 50,
      cell: (info) => (
        <Checkbox
          onChange={() => {
            onCheckTable(info.row.original);
          }}
          value={info.row.original.id_product}
          checked={info.row.original.checked}
        />
      ),
    },
    {
      accessorKey: "product_name",
      header: "Nama Produk",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "sku",
      header: "SKU",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "category.category_name",
      header: "Kategori",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "capital_price",
      header: "Harga Beli",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
    {
      accessorKey: "selling_price",
      header: "Harga Jual",
      cell: (info) => <DefaultColumn>{info.getValue()}</DefaultColumn>,
    },
  ];
};

const DUMMY_DATA = [
  {
    id: 1,
    date: "01 Januari 2024",
    no: "PO/220523/RQ7",
    provider: "CV Harapan Jaya",
    created_by: "Dwi Setiawan",
    bill: "Rp 2.000.000",
    status: 0,
  },
  {
    id: 2,
    date: "01 Januari 2024",
    no: "PO/220523/RQ7",
    provider: "CV Harapan Jaya",
    created_by: "Dwi Setiawan",
    bill: "Rp 2.000.000",
    status: 1,
  },
  {
    id: 3,
    date: "01 Januari 2024",
    no: "PO/220523/RQ7",
    provider: "CV Harapan Jaya",
    created_by: "Dwi Setiawan",
    bill: "Rp 2.000.000",
    status: 2,
  },
  {
    id: 3,
    date: "01 Januari 2024",
    no: "PO/220523/RQ7",
    provider: "CV Harapan Jaya",
    created_by: "Dwi Setiawan",
    bill: "Rp 2.000.000",
    status: 3,
  },
];

const DUMMY_DATA_PRODUCT = [
  {
    id: 1,
    name: "Produk A",
    sku: "SKU#12345",
    category: "Makanan",
    purchase_price: "Rp 50.000",
    selling_price: "Rp 100.000",
  },
  {
    id: 2,
    name: "Produk B",
    sku: "SKU#12345",
    category: "Minuman",
    purchase_price: "Rp 50.000",
    selling_price: "Rp 100.000",
  },
];

const defaultFieldValues = {
  id_user: getUser()?.id || "",
  created_by: getUser()?.name || "",
  no_so: "",
  id_gudang: "",
  status: 2,
  so_date: new Date(),
  so_details: [],
  notes: "",
  formType: FORM_MODE.CREATE,
};

export {
  TABLE_COLUMN,
  DUMMY_DATA,
  STATUS_ENUM,
  TABLE_COLUMN_PRODUCT_SELECTED,
  TABLE_COLUMN_PRODUCT,
  DUMMY_DATA_PRODUCT,
  validations,
  defaultFieldValues,
  FORM_MODE,
};
