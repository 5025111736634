import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { STATUS_ENUM } from "../config/enum";
import { useState } from "react";
import { updatePO } from "apis/purchase_order";
import { useToast } from "@chakra-ui/react";
import { updateStockOpname } from "apis/stock_opname";
import { updateTransferStockgudang } from "apis/trf_stock_gudang";

const StatusColumn = ({ status, data }) => {
  const toast = useToast({ id: status });
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [loading, setLoading] = useState(false);
  const options = Object.keys(STATUS_ENUM).map((st) => {
    let value = { label: "", style: "" };

    switch (STATUS_ENUM[st]) {
      case STATUS_ENUM.DRAFT:
        value = { label: "Draf", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      case STATUS_ENUM.SUCCESS:
        value = { label: "Selesai", style: "bg-[#EBFAF5] text-[#04C99E]" };
        break;

      case STATUS_ENUM.CANCEL:
        value = { label: "Batal", style: "bg-[#E5E5E5] text-[#0A1A1B]" };
        break;

      default:
        break;
    }

    return {
      label: (
        <div
          className={`rounded-full px-2 py-1 tracking-wide ${value.style} w-fit text-xs`}
        >
          {st}
        </div>
      ),
      value: STATUS_ENUM[st],
    };
  });

  return (
    <InputMultipleSelect
      initialLabel={
        loading
          ? "Loading ..."
          : options.find((opt) => opt.value === status)?.label
      }
      value={selectedStatus}
      fetchList={null}
      disabled={loading}
      onChange={async (value) => {
        if (value) {
          setLoading(true);

          try {
            await updateTransferStockgudang({
              id_transfer_stock: data.id_transfer_stock,
              id_user: data.id_user,
              no_ts: data.no_ts,
              ts_date: data.ts_date,
              id_gudang_destination: data.id_gudang_destination,
              id_gudang_source: data.id_gudang_source,
              status: value,
              ts_details: data.transfer_stock_details.map((detail) => ({
                id_transfer_stock_detail: detail.id_transfer_stock_detail,
                id_product: detail.product.id_product,
                qty: detail.qty,
                id_unit: detail.id_unit ?? 1,
                notes: detail.notes || "",
              })),
            });

            setSelectedStatus(value);

            toast({
              title: "Sukses edit status",
              status: "success",
              isClosable: true,
              position: "top-center",
            });
          } catch (err) {
            toast({
              title: err,
              status: "error",
              isClosable: true,
              position: "top-center",
            });
          } finally {
            setLoading(false);
          }
        }
      }}
      optionList={options}
      hideSearch
    />
  );
};

export default StatusColumn;
