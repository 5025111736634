import httpService from "services/api.service";

const BASE_URL = "/transfer_stock";

export const getTransferStockgudang = async (payload) => {
  try {
    const res = await httpService.get(BASE_URL, { params: payload });
    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const createTransferStockgudang = async (payload) => {
  try {
    const res = await httpService.post(BASE_URL, payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};

export const updateTransferStockgudang = async (payload) => {
  try {
    const res = await httpService.put(BASE_URL, payload);

    return res;
  } catch (error) {
    throw error.response.data.msg;
  }
};
