import InputField from "components/fields/InputField";
import { getErrorField } from "helper/geterrorfield";
import React, { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ContextPurchaseOrder } from "../context/ContextProvider";
import InputDatepicker from "components/Form/InputDatepicker";
import Button from "components/Button";
import { TABLE_COLUMN_PRODUCT_SELECTED } from "../config/enum";
import Table from "components/Table";
import Text from "components/Text";
import InputSelect from "components/Form/InputSelect";
import { getUser } from "helper/localstorage";
import InputMultipleSelect from "components/Form/InputMultipleSelect";
import { getGudang } from "apis/gudang";

const Form = () => {
  const { setOpenProduct, isEdit, isDetail, locationList } =
    useContext(ContextPurchaseOrder);
  const forms = useFormContext();

  useEffect(() => {
    forms.setValue("created_by", forms.watch("created_by") || getUser().name);
  }, []);

  const handleDelete = (selectedItem) => {
    const deletedData = forms
      .watch("so_details")
      .filter((item) => item.id_product !== selectedItem.id_product);

    forms.setValue("so_details", deletedData);
  };

  const handleChangeData = (type, val, id_product) => {
    const changedData = forms.watch("so_details").map((item) => {
      return {
        ...item,
        ...(item.id_product === id_product && { [type]: val }),
      };
    });

    forms.setValue("so_details", changedData);
  };

  console.log(forms.watch())

  return (
    <div>
      <div className="mt-5 grid grid-cols-4 gap-4">
        <InputField
          label="Pembuat Transfer Stok"
          register={forms.register("created_by")}
          state={
            getErrorField(forms.formState.errors, "created_by").show
              ? "error"
              : ""
          }
          required
          errors={getErrorField(forms.formState.errors, "created_by")}
          disabled
        />
        <InputField
          label="Nomor Transfer Stok"
          placeholder=""
          required
          disabled
        />
        <InputDatepicker
          disabled={isEdit}
          label="Tanggal Transfer Stok"
          className="h-[50px] w-full"
          onChange={(e) => forms.setValue("so_date", e)}
          value={forms.watch("so_date")}
          required
        />

        <InputField
          label="Catatan"
          disabled={isEdit}
          register={forms.register("note")}
          state={
            getErrorField(forms.formState.errors, "note").show ? "error" : ""
          }
          errors={getErrorField(forms.formState.errors, "note")}
        />
      </div>
      <div className="mt-5 grid grid-cols-2 gap-4">
        <InputMultipleSelect
          fetchList={getGudang}
          keyLabel="gudang_name"
          required
          noLimit
          initialLabel={isDetail ? forms.watch('gudang_source.gudang_name') : ''}
          label="Gudang Asal"
          keyValue="id_gudang"
          onChange={(value) => {
            forms.setValue("id_gudang_source", value);
          }}
          value={forms.watch("id_gudang_source")}
          errors={getErrorField(forms.formState.errors, "id_gudang_source")}
          disabled={isDetail || isEdit}
        />
        <InputMultipleSelect
          fetchList={getGudang}
          keyLabel="gudang_name"
          required
          noLimit
          label="Gudang Tujuan"
          initialLabel={isDetail ? forms.watch('gudang_destination.gudang_name') : ''}
          keyValue="id_gudang"
          onChange={(value) => {
            forms.setValue("id_gudang_destination", value);
          }}
          value={forms.watch("id_gudang_destination")}
          errors={getErrorField(
            forms.formState.errors,
            "id_gudang_destination"
          )}
          disabled={isDetail || isEdit}
        />
      </div>
      <div className="my-3 mr-2 border-b-[1px] border-[#E4E5E8]"></div>
      <div className="mb-3 mr-2 flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <Text variant="h3" content="Rincian Produk" />
          <Text
            variant="p"
            content={`${forms.watch("so_details")?.length} Produk Dipilih`}
          />
        </div>
        <div className="w-1/7 flex">
          <Button
            label="Atur Produk"
            variant="SECONDARY"
            disabled={!forms.watch("id_gudang_source") || isDetail || isEdit}
            onClickHandler={() => {
              setOpenProduct(true);
            }}
            customStyle="w-full justify-center h-[40px]"
            size="sm"
          />
        </div>
      </div>
      <Table
        columnsData={TABLE_COLUMN_PRODUCT_SELECTED(
          handleDelete,
          handleChangeData,
          { isDetail, isEdit }
        )}
        tableData={forms.watch("so_details")}
        hidePagination
      />
      {/* <div className="mt-5 flex flex-col items-end justify-end pr-5">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <span
              className={`text-sm font-bold text-textDefault dark:text-white`}
            >
              Subtotal
            </span>
            <span>
              {currency(
                forms
                  .watch("so_details")
                  .reduce((prev, curr) => prev + curr?.qty * curr.price, 0)
              )}
            </span>
          </div>
          <InputField
            label="Biaya Tambahan"
            type="number"
            disabled={isEdit || isDetail}
            register={forms.register("other_cost")}
            state={
              getErrorField(forms.formState.errors, "other_cose").show
                ? "error"
                : ""
            }
            errors={getErrorField(forms.formState.errors, "other_cost")}
          />
          <div className="flex flex-col">
            <span
              className={`text-sm font-bold text-textDefault dark:text-white`}
            >
              Total
            </span>
            <span>
              {currency(
                forms
                  .watch("so_details")
                  .reduce((prev, curr) => prev + curr?.qty * curr.price, 0) +
                  +forms.watch("other_cost", 0)
              )}
            </span>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Form;
