/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";
import { MdExpandMore } from "react-icons/md";
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/accordion";
import MenuItem from "./MenuItem";
import { getUser } from "helper/localstorage";
// chakra imports

export function SidebarLinks(props) {
    // Chakra color mode
    let location = useLocation();

    const { routes } = props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === `/admin/${routeName}`;
    };

    const createLinks = (routes) => {
        return routes.filter(route => route.layout !== '/auth').map((route, index) => {
            if (route.child) {
                return (
                    <Accordion className='relative' allowMultiple key={`menu-${index}`}>
                        <AccordionItem className='py-3 pl-3'>
                            <h2>
                                <AccordionButton className='flex'>
                                    <MenuItem name={route.name} isActive={activeRoute(route.path)} isHaveChild={route.child} icon={route.icon} path={route.path} />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel className="relative text-left text-medium mt-2">
                                {route.child && route.child.map((child, childIndex) => {
                                    const userData = getUser();
                                    return (userData.id_role !== 1 && child.path === 'sales-and-customer/buat-user') ? null : (
                                        <Link key={`child-${childIndex}`} to={child.layout + "/" + child.path}>
                                            <div className='relative py-1 pl-2'>
                                                <MenuItem name={child.name} isActive={activeRoute(child.path)} isHaveChild={child.child} showIcon={false} icon={child.icon} path={child.path} />
                                            </div>
                                        </Link>
                                    )
                                })}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                );
            } else {
                return (
                    <div className="relative py-3 pl-3" key={`menu-${index}`}>
                        <Link to={route.layout + "/" + route.path}>
                            <MenuItem name={route.name} isActive={activeRoute(route.path)} isHaveChild={route.child} icon={route.icon} path={route.path} />
                        </Link>
                    </div>
                );
            }
        });
    };

    return createLinks(routes);
}

export default SidebarLinks;
